





























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Booking from '@/models/booking/Booking'
import BookingInfo from '@/components/booking/BookingInfo.vue'

@Component({
  components: {
    BookingInfo,
  },
})
export default class BookingDialog extends Vue {
  @Prop({ type: Booking, required: false })
  private booking: Booking

  @Prop({ type: Boolean, required: false })
  private value: boolean

  @Prop()
  private services

  @Prop()
  private resources

  @Prop()
  private places

  @Prop()
  private draft

  @Prop()
  private persistent

  private visible = false

  // ================================================================
  // Manage dialog visibility
  // ================================================================

  // On created, start with the visibility given by the v-model prop
  public created(): void {
    if (this.value) {
      this.visible = true
    }
  }

  // If the v-model prop is changed from outside, update our visibility
  @Watch('value')
  private onValueChange(value) {
    this.visible = value
  }

  // If we programmatically close dialog in here, emit to the v-model outside
  private close(hadChanges) {
    // Keep track of whether we close after an update/new (hadChanges=true)
    // or if we just closed the window without submitting (hadChanges=false).
    // Stuff like Calendar.onAbortNew() uses this to keep track of what should be kept.
    if (!hadChanges) {
      this.$emit('abort')
    }
    this.$emit('input', false)
  }

  // If user clicks outside the dialog to close it, also emit to the v-model outside
  private onClickOutside() {
    if (this.persistent) {
      return
    }
    this.close(false)
  }

  // When booking-info says updated, we pass that on + close the dialog
  private onUpdate() {
    this.$emit('update')
    this.close(true)
  }

  private onSubmitNew(data) {
    this.$emit('submitNew', data)
    this.close(true)
  }

  private onDiscard() {
    this.$emit('discard')
    this.close(true)
  }
}
