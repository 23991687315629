export class BookingAddonServiceConnection {
  public primaryServiceId: number
  public isRequired: boolean

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.primaryServiceId = data.primaryServiceId ? (data.primaryServiceId as number) : null
    this.isRequired = data.isRequired as boolean
  }
}

export default class BookingService {
  public id: number
  public calendarId: number
  public code: string
  public name: string
  public duration: number
  public mode: string
  public sortOrder: number
  public type: string
  public status: string
  public salesPrice: string
  public primaryServices: Array<BookingAddonServiceConnection>

  // NB: are more fields from backend, but we don't need them yet
  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.calendarId = data.calendarId ? (data.calendarId as number) : null
    this.code = data.code ? (data.code as string) : ''
    this.name = data.name ? (data.name as string) : ''
    this.duration = data.duration ? (data.duration as number) : null
    this.mode = data.mode ? (data.mode as string) : ''
    this.sortOrder = data.sortOrder ? (data.sortOrder as number) : null
    this.type = data.type ? (data.type as string) : ''
    this.status = data.status ? (data.status as string) : ''
    this.salesPrice = data.salesPrice ? (data.salesPrice as string) : ''
    this.primaryServices = []
    if (data.primaryServices) {
      const ps = data.primaryServices as Array<Record<string, unknown>>
      for (let i = 0; i < ps.length; i++) {
        this.primaryServices.push(new BookingAddonServiceConnection(ps[i]))
      }
    }
  }

  public isForPrimaryServiceId(id: number): boolean {
    for (let i = 0; i < this.primaryServices.length; i++) {
      if (this.primaryServices[i].primaryServiceId === id) {
        return true
      }
    }
    return false
  }

  public get isAddon() {
    return this.type !== 'Primary'
  }

  public get isActive() {
    return this.status.startsWith('Active')
  }
}
