export default class BookingPlace {
  public id: number
  public calendarId: number
  public name: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.calendarId = data.calendarId ? (data.calendarId as number) : null
    this.name = data.name ? (data.name as string) : ''
  }
}
